import { useMemo } from 'react';

import { selectSessionId, selectUserId } from '../store/session';
import { useAppSelector } from './useAppSelector';

const useBackendUserIdentifier = () => {
	const userId = useAppSelector(selectUserId);
	const sessionId = useAppSelector(selectSessionId);

	return useMemo(() => {
		if (userId) {
			return {
				type: 'user' as const,
				value: userId,
			};
		}

		if (sessionId) {
			return {
				type: 'session' as const,
				value: sessionId,
			};
		}
	}, [userId, sessionId]);
};

export default useBackendUserIdentifier;
