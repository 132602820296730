import { useSanityImageAttributes } from '@wearemojo/ui-components';
import { useCallback } from 'react';

import { FragmentType } from '../content/FragmentType';
import { logger } from '../utils/logging';
import { useOptionalFragment } from './content/useFragment';

const defaultCoachKey = 'mateo';

const useCoachImage = () => {
	const images = useOptionalFragment(FragmentType.Coaches)?.images;
	const imageAttr = useSanityImageAttributes(440);

	const getCoachImageUri = useCallback(
		(key?: string) => {
			if (!images) {
				// On first render the fragments might not have loaded yet, so we return early
				return undefined;
			}

			const image =
				images.find((i) => i.optionKey === key)?.image ||
				images.find((i) => i.optionKey === defaultCoachKey)?.image;

			if (!image) {
				logger.captureError('Default coach image not found', {
					images,
					key,
					defaultCoachKey,
				});
				return undefined;
			}

			const imageUri = imageAttr(image).uri;
			return imageUri;
		},
		[images, imageAttr],
	);

	return {
		getCoachImageUri,
	};
};

export default useCoachImage;
