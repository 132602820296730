import { useEffect, useMemo } from 'react';

import useCurrentTrackingDate from '../store/api/hooks/useCurrentTrackingDate';
import { removeOnceKeys, selectOnce } from '../store/once';
import { selectUserId } from '../store/session';
import useAppDispatch from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

/**
 * Some once keys "reset" every day. Examples being keys like `streak_gained/2024-09-01`
 * and `home_tasks_complete/2024-09-01`. The problem this causes is that the redux state
 * that holds these keys is getting bigger and bigger, and SecureStorage has a maximum
 * size of 2048 bytes. To solve that, we've set up this hook to remove the keys for days
 * that have already passed and are not useful anymore.
 */
const useCleanupOnceKeys = () => {
	const dispatch = useAppDispatch();
	const userId = useAppSelector(selectUserId);
	const once = useAppSelector(selectOnce);

	const currentTrackingDate = useCurrentTrackingDate();
	const currentTrackingDateString = currentTrackingDate?.toString();

	const allOnceKeys = useMemo(() => Object.keys(once), [once]);

	const keysToRemove = useMemo<string[]>(() => {
		if (!userId || !currentTrackingDateString) return [];

		const toRemove = allOnceKeys.filter(
			(key) =>
				(key.startsWith('home_tasks_complete/') &&
					key !== `home_tasks_complete/${currentTrackingDateString}`) ||
				(key.startsWith('streak_gained/') &&
					key !== `streak_gained/${currentTrackingDateString}`) ||
				(key.startsWith('daily_initial_loading/') &&
					key !== `daily_initial_loading/${currentTrackingDateString}`),
		);

		return toRemove;
	}, [userId, currentTrackingDateString, allOnceKeys]);

	useEffect(() => {
		if (keysToRemove.length === 0) return;

		dispatch(removeOnceKeys(keysToRemove));
	}, [dispatch, keysToRemove]);
};

export default useCleanupOnceKeys;
