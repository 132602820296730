import { ActionType } from '@wearemojo/sanity-schema';
import { InlineContent } from '@wearemojo/ui-components';
import { useEffect, useMemo } from 'react';

import api from '../store/api';
import { logger } from '../utils/logging';
import fallbackError from './fallbackError';

export type ErrorDefinition = {
	code: string;
	type: 'generic' | 'connection';
	title: InlineContent;
	message: InlineContent;
	actions: ActionType[];
	ctaLabel?: string;
};

export type ErrorDefinitions = {
	defaultError: Omit<ErrorDefinition, 'code'>;
	errors: Record<string, ErrorDefinition>;
};

const defaultMessages: ErrorDefinitions = {
	defaultError: fallbackError,
	errors: {},
};

const useErrorDefinitions = (): ErrorDefinitions => {
	const { data, error } = api.endpoints.getErrorMessages.useQuery();

	useEffect(() => {
		if (error) {
			logger.captureWarning('failed to fetch error messages', { error });
		}
	}, [error]);

	return useMemo(() => {
		if (!data) return defaultMessages;

		const { defaultError, defaultTitle, errors } = data;

		return {
			defaultError: {
				...defaultError,
				title: defaultError.title ?? defaultTitle,
				actions: defaultError.actions ?? [],
			},
			errors: errors.reduce<ErrorDefinitions['errors']>((acc, curr) => {
				acc[curr.code] = {
					...curr,
					title: curr.title ?? defaultTitle,
					actions: curr.actions ?? [],
				};
				return acc;
			}, {}),
		};
	}, [data]);
};

export default useErrorDefinitions;
