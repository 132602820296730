import * as SentryBrowser from '@sentry/browser';
import { captureConsoleIntegration } from '@sentry/core';
import { useEffect } from 'react';
import { Platform } from 'react-native';

import { defaultBackendConfig, defaultSentryConfig } from '../../environment';
import { useAppSelector } from '../hooks/useAppSelector';
import Sentry from '../services/sentry';
import { selectUserId } from '../store/session';
import { getRuntimeInfo } from '../utils/buildInfo';

const getWebSentryConfig = (): SentryBrowser.BrowserOptions => {
	if (Platform.OS !== 'web') {
		return {};
	}

	const { version, commitSha } = getRuntimeInfo();
	const release = `${version ? `v${version}-` : ''}${
		commitSha ? commitSha.substring(0, 8) : ''
	}`;

	return {
		release,
	};
};

const messagesToIgnore = [
	'[Reanimated] useAnimatedKeyboard is not available on web yet.',
	"FlashList's rendered size is not usable.",
	'This method is deprecated (as well as all React Native Firebase namespaced API)',
];

export const initSentry = () => {
	Sentry.init({
		...defaultSentryConfig, // TODO: can we allow clientsdkconfig to override this?
		...getWebSentryConfig(),
		environment: defaultBackendConfig.env, // TODO: make this dynamic
		enableNativeNagger: true,
		enableAutoSessionTracking: true,
		enableAutoPerformanceTracing: true,
		integrations: [
			captureConsoleIntegration({
				levels: ['error', 'warn'],
			}),
		],
		normalizeDepth: 5,
		beforeSend: (event) => {
			const message = event?.message;
			// Avoid generating Sentry events for common, expected logging
			if (message && messagesToIgnore.some((msg) => message.startsWith(msg))) {
				return null;
			}
			return event;
		},
	});
};

export const useSentryIdentify = () => {
	const userId = useAppSelector(selectUserId);

	useEffect(() => {
		if (!userId) {
			Sentry.setUser(null); // clears Sentry user
			return;
		}

		Sentry.setUser({ id: userId });
	}, [userId]);
};
