export enum AnalyticsEvent {
	// https://help.mixpanel.com/hc/en-us/articles/360038439952#add-experiments-to-an-implementation
	mp_experiment_started = '$experiment_started',

	app_loaded = 'app_loaded',
	screen_viewed = 'screen_viewed',
	home_task_pressed = 'home_task_pressed',
	activity_completed = 'activity_completed',
	activity_learning_completed = 'activity_learning_completed',
	activity_learning_started = 'activity_learning_started',
	activity_started = 'activity_started',
	activity_staging_choice_selected = 'activity_staging_choice_selected',
	animation_started = 'animation_started',
	animation_completed = 'animation_completed',
	audio_started = 'audio_started',
	audio_majority_completed = 'audio_majority_completed',
	audio_completed = 'audio_completed',
	video_started = 'video_started',
	video_majority_completed = 'video_majority_completed',
	video_completed = 'video_completed',
	poll_response_recorded = 'poll_response_recorded',
	submit_wrap_up = 'submit_wrap_up',
	tracking_button_pressed = 'tracking_button_pressed',
	opted_in_to_erotic_stories_upsell = 'opted_in_to_erotic_stories_upsell',
	efficacy_measurement_question_answered = 'efficacy_measurement_question_answered',
	efficacy_measurement_completed = 'efficacy_measurement_completed',
	efficacy_measurement_dismissed = 'efficacy_measurement_dismissed',
	modal_closed = 'modal_closed',
	logged_out = 'logged_out',
	account_deletion_self_serve = 'account_deletion_self_serve',
	loading_indicator_persisted = 'loading_indicator_persisted',
	notification_permission_requested = 'notification_permission_requested',
	notification_permission_granted = 'notification_permission_granted',
	notification_time_preference = 'notification_time_preference',
	web_continue_link_pressed = 'web_continue_link_pressed', // nudge
	app_store_button_pressed = 'app_store_button_pressed', // nudge
	play_store_button_pressed = 'play_store_button_pressed', // nudge
	paid_trial_option_chosen = 'paid_trial_option_chosen',
	checkout_button_pressed = 'checkout_button_pressed',
	checkout_close_button_pressed = 'checkout_close_button_pressed',
	make_payment_button_pressed = 'make_payment_button_pressed',
	make_payment_button_completed = 'make_payment_button_completed',
	subscription_cancelation_feedback_completed = 'subscription_cancelation_feedback_completed',
	subscription_cancelation_step_completed = 'subscription_cancelation_step_completed',
	subscription_cancelation_discount_applied = 'subscription_cancelation_discount_applied',
	subscription_canceled = 'subscription_canceled',
	onboarding_page_notification_requested = 'onboarding_page_notification_requested',
	error_presented = 'error_presented',
	error_needs_mapping = 'error_needs_mapping',
	auth_requested = 'auth_requested',
	auth_cred_received = 'auth_cred_received',
	auth_completed = 'auth_completed',
	auth_approval_presented = 'auth_approval_presented',
	auth_approval_code = 'auth_approval_code',
	auth_approval_revoked = 'auth_approval_revoked',
	auth_requester_presented = 'auth_requester_presented',
	payment_paywalled_card_viewed = 'payment_paywalled_card_viewed',
	payment_paywalled_cta_pressed = 'payment_paywalled_cta_pressed',
	payment_paywalled_popup_cta_pressed = 'payment_paywalled_popup_cta_pressed',
	payment_failed_card_viewed = 'payment_failed_card_viewed',
	payment_failed_cta_pressed = 'payment_failed_cta_pressed',
	payment_failed_popup_cta_pressed = 'payment_failed_popup_cta_pressed',
	in_app_review_requested = 'in_app_review_requested',
	app_review_experience_rated = 'app_review_experience_rated',
	app_review_cta_pressed = 'app_review_cta_pressed',
	iap_checkout_button_pressed = 'iap_checkout_button_pressed',
	iap_restore_purchase_button_pressed = 'iap_restore_purchase_button_pressed',
	iap_subscription_completed = 'iap_subscription_completed',
	iap_subscription_failed = 'iap_subscription_failed',
	free_user_first_course_popup_viewed = 'free_user_first_course_popup_viewed',
	free_user_first_course_popup_cta_pressed = 'free_user_first_course_popup_cta_pressed',
	cookies_seen_in_app_at_startup = 'cookies_seen_in_app_at_startup',
	cookie_consent_shown = 'cookie_consent_shown',
	cookie_consent_updated = 'cookie_consent_updated',
	screenshot_taken = 'screenshot_taken',
	community_topic_created = 'community_topic_created',
	community_reply_posted = 'community_reply_posted',
	community_topic_like_toggled = 'community_topic_like_toggled',
	community_reply_like_toggled = 'community_reply_like_toggled',
	community_topics_filtered = 'community_topics_filtered',
	community_topics_sorted = 'community_topics_sorted',
	course_community_reply_posted = 'course_community_reply_posted',
	course_community_reply_like_toggled = 'course_community_reply_like_toggled',
	kickstart_challenge_banner_pressed = 'kickstart_challenge_banner_pressed',
	kickstart_challenge_missed_modal_opened = 'kickstart_challenge_missed_modal_opened',
	kickstart_challenge_missed_modal_button_pressed = 'kickstart_challenge_missed_modal_button_pressed',
	kickstart_challenge_congrats_modal_opened = 'kickstart_challenge_congrats_modal_opened',
	kickstart_challenge_congrats_modal_button_pressed = 'kickstart_challenge_congrats_modal_button_pressed',
	register_preface_exit_cta_pressed = 'register_preface_exit_cta_pressed',
	register_preface_exit_button_pressed = 'register_preface_exit_button_pressed',
	register_preface_skip_button_pressed = 'register_preface_skip_button_pressed',
	register_preface_cancel_button_pressed = 'register_preface_cancel_button_pressed',
	streaks_header_button_pressed = 'streaks_header_button_pressed',
	goal_header_button_pressed = 'goal_header_button_pressed',
	streaks_consecutive_days_recorded = 'streaks_consecutive_days_recorded',
	streaks_commitment_recorded = 'streaks_commitment_recorded',
	sensitive_data_consent_updated = 'sensitive_data_consent_updated',
	unlock_activity_cta_pressed = 'unlock_activity_cta_pressed',
	intercom_presented = 'intercom_presented',
	refer_share_pressed = 'refer_share_pressed',
	refer_share_mobile_completed = 'refer_share_mobile_completed',
	day_picker_pressed = 'day_picker_pressed',
	week_picker_pressed = 'week_picker_pressed',
	one_activity_a_day_swapped = 'one_activity_a_day_swapped',
	audio_card_pressed = 'audio_card_pressed',
	checkout_pricing_displayed = 'checkout_pricing_displayed',
	challenge_accepted = 'challenge_accepted',
	challenge_declined = 'challenge_declined',
	user_name_filled = 'user_name_filled',
	user_name_skipped = 'user_name_skipped',
	ito_chat_message_sent = 'ito_chat_message_sent',
	ito_action_pressed = 'ito_action_pressed',
	ito_thread_error_pressed = 'ito_thread_error_pressed',
	ito_thread_error_shown = 'ito_thread_error_shown',
	flow_input_filled = 'flow_input_filled',
	flow_input_skipped = 'flow_input_skipped',
	ito_dislike_message_pressed = 'ito_dislike_message_pressed',
	ito_report_message_pressed = 'ito_report_message_pressed',
	ito_functional_message_selected = 'ito_functional_message_selected',
	onboarding_variant_forced = 'onboarding_variant_forced',
	plan_check_in_completed = 'plan_check_in_completed',
	library_filter_tab_pressed = 'library_filter_tab_pressed',
}
