import { ProgramActivityStateType } from '@wearemojo/sanity-schema';
import { useCallback, useMemo } from 'react';

import useUserActivity from '../store/api/hooks/useUserActivity';
import { selectIsLoggedIn } from '../store/session';
import { isNonNullable } from '../utils/arrayUtils';
import { useActivities } from './content/useActivities';
import { useAppSelector } from './useAppSelector';

export const useActivityLockStatus = () => {
	const { activityById } = useActivities();
	const { userActivity } = useUserActivity();
	const isLoggedIn = useAppSelector(selectIsLoggedIn);

	const completedActivities = useMemo(() => {
		if (!userActivity || !isLoggedIn) return [];
		return Array.from(
			new Set(
				userActivity
					.map((ua) => {
						if (ua.type !== 'activity_completed') return;
						const act = activityById(ua.params.activityId);
						if (act?.state === ProgramActivityStateType.Live) return act;
					})
					.filter(isNonNullable),
			),
		);
	}, [activityById, userActivity, isLoggedIn]);

	const isLocked = useCallback(
		(activityId: string) => {
			if (!isLoggedIn) return true;

			const activity = activityById(activityId);
			if (!activity) return true;

			return !completedActivities?.some((act) => act.id === activityId);
		},
		[completedActivities, activityById, isLoggedIn],
	);

	return { isLocked };
};
